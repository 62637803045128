.hr::before {
  content: '';
  position: absolute;
  left: 50%;
  right: 50%;
  margin: 0 -50vw;
  height: 1px;
  width: 100vw;
  background-color: inherit;
}
