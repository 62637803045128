@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./colors.css');

:root {
  --header-height: 64px;
  --z-index-header: 9;
  --z-index-sub-header: 8;
  --z-index-bottom-bar: 20;
  --z-index-bottom-sub-bar: 19;
  --tyre-details-bottom-bar: 72px;
  --compare-tyres-bottom-bar: 64px;
}

html {
  @apply text-text-grey-dark;
  font-size: 16px;
  font-family: var(--font-lato);
  text-rendering: optimizeLegibility;
  text-shadow: 0 0 1px rgb(0 0 0 / 1%);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}

body {
  height: 100%;
  overflow-x: hidden;
  padding-bottom: calc(var(--compare-tyres-bottom-bar-offset, 0px) + var(--tyre-details-bottom-bar-offset, 0px));
}

body[data-compare-tyres] {
  --compare-tyres-bottom-bar-offset: var(--compare-tyres-bottom-bar);
}

body[data-tyre-details-bottom-bar] {
  --tyre-details-bottom-bar-offset: var(--tyre-details-bottom-bar);
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
