:root {
  --color-white: #fff;
  --color-black: #000;
  --color-yellow: #ffd300;

  --color-grey-1000: #2e2e33;
  --color-grey-1000_20: rgba(46, 46, 51, 0.2);
  --color-grey-900: #494952;
  --color-grey-800: #737380;
  --color-grey-700: #9999a6;
  --color-grey-600: #b6b6c1;
  --color-grey-500: #d2d2d9;
  --color-grey-400: #e4e4eb;
  --color-grey-300: #ededf5;
  --color-grey-200: #f5f5fa;
  --color-grey-100: #f7f7fa;
  --color-grey-50: #fafafc;

  --color-blue-700: #0057ff;
  --color-blue-500: #2f8cff;
  --color-blue-50: #e8f9ff;

  --color-orange-600: #e09d00;
  --color-orange-500: #ffbf00;
  --color-orange-400: #ffd11a;
  --color-orange-200: #fff885;

  --color-green-700: #497c0f;
  --color-green-500: #7dcc16;
  --color-green-400: #99e52e;

  --color-red-600: #ff1e00;
  --color-red-500: #ff370a;
  --color-red-400: #ff5c33;
  --color-red-100: #ffded1;

  --color-beige-900: #645420;
  --color-beige-100: #faf5e6;
  --color-beige-50: #faf5e6;

  --color-text-grey-dark: var(--color-grey-1000);
  --color-text-grey-standard: var(--color-grey-900);
  --color-text-grey-semi: var(--color-grey-800);
  --color-text-grey-light: var(--color-grey-600);
  --color-text-grey-super-light: var(--color-grey-500);
  --color-text-white: #fff;
  --color-text-red: var(--color-red-500);
  --color-text-blue: var(--color-blue-700);
  --color-text-orange: var(--color-orange-500);
  --color-text-orange-dark: var(--color-orange-600);
  --color-text-green: var(--color-green-700);
}
